// react-router-dom components
import { Link } from "react-router-dom";
import { useEffect } from "react";
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "../../components/MKBox";
import MKTypography from "../../components/MKTypography";

// Material Kit 2 PRO React examples
import SimpleBackgroundCard from "../../shared/components/Cards/BackgroundCards/SimpleBackgroundCard";
// Routes
import routes from "../../shared/util/routes/routes";
// Material Kit 2 PRO React examples
import DefaultNavbar from "../../shared/components/Navigation/DefaultNavbar";
// Images
import post1 from "../../assets/images/premio1.png";
import post2 from "../../assets/images/premio2.png";
import post3 from "../../assets/images/premio3.png";
import image from "../../assets/images/prizesCover.png";
// Import the redux methods
import { GetUser } from "../../redux/modules/user";
import { CheckUser } from "../../redux/modules/user";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
function BlogPostFour({ coverHeight, children }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { uToken } = useSelector((state) => state.user);
  useEffect(() => {
    dispatch(
      CheckUser(uToken !== "" ? uToken : localStorage.getItem("key"), navigate)
    );
  }, []);
  return (
    <MKBox height="calc(100vh - 1rem)">
      <MKBox width="100%" position="absolute" top="0.25rem">
        <DefaultNavbar routes={routes} dark />
        <MKBox
          width="calc(100% - 2rem)"
          minHeight={coverHeight}
          borderRadius="xl"
          mx={2}
          my={2}
          pt={6}
          pb={28}
          sx={{
            backgroundImage: ({
              functions: { linearGradient, rgba },
              palette: { gradients },
            }) =>
              image &&
              `${linearGradient(
                rgba(gradients.dark.main, 0),
                rgba(gradients.dark.state, 0)
              )}, url(${image})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        />
        <MKBox component="section" py={3}>
          <Container>
            <Grid container spacing={3} mt={3}>
              <Grid item xs={12} lg={4}>
                <Link to="/sections/page-sections/blog-posts">
                  <SimpleBackgroundCard
                    image={post1}
                    title="10000 Puntos"
                    description="Obten una nueva computadora HP!"
                  />
                </Link>
              </Grid>
              <Grid item xs={12} lg={4}>
                <Link to="/sections/page-sections/blog-posts">
                  <SimpleBackgroundCard
                    image={post2}
                    title="20000 Puntos"
                    description="Obten una nueva consola Playstation!!"
                  />
                </Link>
              </Grid>
              <Grid item xs={12} lg={4}>
                <Link to="/sections/page-sections/blog-posts">
                  <SimpleBackgroundCard
                    image={post3}
                    title="30000 Puntos"
                    description="Obten una nueva tablet samsung!!"
                  />
                </Link>
              </Grid>
            </Grid>
          </Container>
        </MKBox>
      </MKBox>
    </MKBox>
  );
}
// Setting default props for the CoverLayout
BlogPostFour.defaultProps = {
  coverHeight: "35vh",
};

export default BlogPostFour;

import { rootShouldForwardProp } from "@mui/material/styles/styled";
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import { createSlice } from "@reduxjs/toolkit";
import axios from "axios"
// Material Kit 2 PRO React components
import MKBox from "../../../components/MKBox";
import MKAvatar from "../../../components/MKAvatar";
import MKTypography from "../../../components/MKTypography";
// Components
function Author({ image, name }) {
  return (
    <MKBox display="flex" alignItems="center" px={1} py={0.5}>
      <MKBox mr={2}>
        <MKAvatar src={image} alt={name} size="sm" variant="rounded" />
      </MKBox>
      <MKBox display="flex" flexDirection="column">
        <MKTypography variant="button" fontWeight="medium">
          {name}
        </MKTypography>        
      </MKBox>
    </MKBox>
  );
}

// Typechecking props for the Author
Author.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

function Role({ job, org }) {
  return (
    <MKBox display="flex" flexDirection="column">
      <MKTypography variant="caption" fontWeight="medium" color="white">
        {job}
      </MKTypography>
      <MKTypography variant="caption" color="textads">
        {org}
      </MKTypography>
    </MKBox>
  );
}

// Typechecking props for the Role
Role.propTypes = {
  job: PropTypes.string.isRequired,
  org: PropTypes.string.isRequired,
};
export const userSlice = createSlice({
  name: "helper",
  initialState: {
    navLinks: "",
    rankList: [],
    length: 0,
  },
  reducers: {
    setNavlinks: (state, actions) => {
      state.navLinks = actions.payload;
    },
    setRanking: (state, actions) => {
      state.rankList = actions.payload;
    },
    setLength: (state, actions) => {
      state.length = actions.payload;
    },    
  },
});
// Method to get the user ranking list
export const GetUsers = (token,Page,ItemsPerPage,navigate) => async (dispatch) => {
  try {
    const response = await axios.get(process.env.REACT_APP_ENPOINT+`/get-users/${Page}/${ItemsPerPage}`, {
      headers: {
        Authorization: token,
      },
    });
    const datos = response.data.data;
    const rows = [];
    let start = Page*ItemsPerPage-(ItemsPerPage-1);
    datos.map((e)=>{
      rows.push({
        Rank:start,
        Nombre: (
          <MKTypography variant="caption" color="white" fontWeight="medium">
            {e.name}
          </MKTypography>
        ),
        Departamento: e.Department.name,
        Puntos: (
          <MKTypography variant="caption" color="white" fontWeight="medium">
            {e.score}
          </MKTypography>
        ),
        key:e.userId
      },)
      start++;     
    })
    dispatch(setRanking(rows))
    dispatch(setLength(response.data.total))
  } catch (error) {
  }
};
// Action creators are generated for each case reducer function
export const { setNavlinks, setRanking, setLength } = userSlice.actions;

export default userSlice.reducer;

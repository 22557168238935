import { Route, Navigate, Routes } from "react-router-dom";
import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Blank from "./home/pages/blankPage";
import Terms from "./pages/terms/termsnconditions";
import Ranking from "./pages/ranking/ranking";
import Illustration from "./layouts/auth";
import CreateAccount from "./layouts/signup";
import UserProfile from "./pages/userProfile"
import Challenges from "./pages/challenges"
import Prizes from "./pages/prize"
import Reset from "./pages/auth/resetpassword"
import NPassword from "./pages/auth/resetpassword/passwordView"
// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "../src/assets/theme";
function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />        
      <ToastContainer />
      <Routes>
        <Route path="/" element={<Illustration />} />
        <Route path="/create-account" element={<CreateAccount />} />
        <Route path="/logged" element={<Blank />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/user" element={<UserProfile />} />
        <Route path="/ranking" element={<Ranking />} />
        <Route path="/desafios" element={<Challenges />} />
        <Route path="/premios" element={<Prizes />} />
        <Route path="/reset" element={<Reset />} />
        <Route path="/password/:params" element={<NPassword/>}/>
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </ThemeProvider>
  );
}

export default App;

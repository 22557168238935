/**
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import { useState } from "react";
import { useEffect } from "react";
// Material Kit 2 PRO React components
import MKBox from "../../../components/MKBox";
import MKTypography from "../../../components/MKTypography";
import MKInput from "../../../components/MKInput";
import MKButton from "../../../components/MKButton";
import { toast } from "react-toastify";
// Authentication layout components
import CoverLayout from "../../../shared/components/Layouts/CoverLayout";

// Images
import bgImage from "../../../assets/images/prizesCover.png";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { newPwd } from "../../../redux/modules/user";
function Cover() {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const rDispatch = useDispatch();
  const [formData, updateFormData] = useState(null);  
  const token = searchParams.get("token");
  const email = searchParams.get("email");
  const handleChange = (e) => {
    updateFormData({
      ...formData,

      // Trimming any whitespace
      [e.target.name]: e.target.value.trim(),
    });
  };
   // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
     // eslint-disable-next-line react-hooks/exhaustive-deps
    if(!token||!email){
       // eslint-disable-next-line react-hooks/exhaustive-deps
        navigate("/")
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const placeSubmitHandler = (event) => {
    event.preventDefault();
    if (formData.password === formData.validate) {
        const payload = {
            email:email,
            token:token,
            password:formData.password
        }
        rDispatch(newPwd(payload,navigate));
    } else {
      toast.error("Las Contraseñas deben de ser iguales");
    }
  };
  return (
    <CoverLayout coverHeight="50vh" image={bgImage}>
      <Card style={{backgroundColor: "#FF7412"}}>
        <MKBox
          variant="gradient"
          bgColor="dark"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          py={2}
          mb={1}
          textAlign="center"
        >
          <MKTypography variant="h3" fontWeight="medium" color="white" mt={1}>
            Nueva Contraseña
          </MKTypography>
          <MKTypography display="block" variant="button" color="white" my={1}>
            Ingresa tu Nueva Contraseña
          </MKTypography>
        </MKBox>
        <MKBox pt={4} pb={3} px={3}>
          <MKBox component="form" role="form" onSubmit={placeSubmitHandler}>
            <MKBox mb={4}>
              <MKInput
                type="password"
                label="Nueva Contraseña"
                name="password"
                variant="standard"
                fullWidth
                InputLabelProps={{ shrink: true }}
                onChange={handleChange}
              />
            </MKBox>
            <MKBox mb={4}>
              <MKInput
                type="password"
                name="validate"
                label="Confirma tu Contraseña"
                variant="standard"
                fullWidth
                InputLabelProps={{ shrink: true }}
                onChange={handleChange}
              />
            </MKBox>
            <MKBox mt={6} mb={1}>
              <MKButton variant="gradient" color="dark" type="submit" fullWidth>
                Restablecer
              </MKButton>
            </MKBox>
          </MKBox>
        </MKBox>
      </Card>
    </CoverLayout>
  );
}

export default Cover;

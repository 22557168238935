import ReactPaginate from "react-paginate";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { GetUsers } from "../../redux/modules/helper";
import { useSelector, useDispatch } from "react-redux";
// Material Kit 2 PRO React examples
import Table from "../../shared/components/Table";
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import "./ranking.css"
// Material Kit 2 PRO React components
import MKBox from "../../components/MKBox";
let items = [];

const Prueba = ({ itemsPerPage }) => {
  const navigate = useNavigate();
  const rDispatch = useDispatch();
  // Columns and Rows
  const columns = [
    { name: "Rank", align: "center" },
    { name: "Nombre", align: "center" }, 
    { name: "Departamento", align: "center" },
    { name: "Puntos", align: "center" },
  ];
  // We start with an empty list of items.
  const [currentItems, setCurrentItems] = useState([]);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);
  const { rankList, length } = useSelector((state) => state.helper);
  const { uToken } = useSelector((state) => state.user);  
  const [pageCount, setPageCount] = useState(0);
  useEffect(() => {
    rDispatch(
      GetUsers(
        uToken !== "" ? uToken : localStorage.getItem("key"),
        1,
        itemsPerPage
      )
    );
  }, []);

  useEffect(() => {
    items = rankList;
    setCurrentItems(items);
  }, [rankList]);
  useEffect(() => {
    setPageCount(Math.ceil(length / itemsPerPage));
  }, [length]);


  useEffect(() => {
    setCurrentItems(items);
    setPageCount(Math.ceil(length / itemsPerPage));
  }, [itemOffset, itemsPerPage]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % length;
    rDispatch(
      GetUsers(
        uToken !== "" ? uToken : localStorage.getItem("key"),
        event.selected + 1,
        itemsPerPage
      )
    );
    items = rankList;
    setItemOffset(newOffset);
  };

  return (
    <>
      <MKBox component="section" py={12}>
        <Container>
          <Card
            style={{ backgroundColor: "#FFD800" }}
            sx={{
              px: 2,
              py: 10,
              mx: { xs: 3, lg: -4 },
              mt: 2,
              mb: 4,
              boxShadow: ({ boxShadows: { xxl } }) => xxl,
            }}
          >
            <Grid container item xs={12} lg={10} mx="auto">
              <Table columns={columns} rows={currentItems} />
              <ReactPaginate
                nextLabel=">"
                onPageChange={handlePageClick}
                pageRangeDisplayed={3}
                marginPagesDisplayed={2}
                pageCount={pageCount}
                previousLabel="<"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item .p"
                previousLinkClassName="page-link"
                nextClassName="page-item .n"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName="pagination"
                activeClassName="active"
              />
            </Grid>
          </Card>
        </Container>
      </MKBox>
    </>
  );
};

export default Prueba;

/**
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Adslive Media (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";
import { useEffect } from "react";

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Switch from "@mui/material/Switch";

// Material Kit 2 PRO React components
import MKBox from "../../../../src/components/MKBox";
import MKTypography from "../../../../src/components/MKTypography";
import MKInput from "../../../../src/components/MKInput";
import MKButton from "../../../../src/components/MKButton";

// Authentication layout components
import IllustrationLayout from "../illustration/index";

// Image
import bgImage from "../../..//assets//images/loginCover.png";
//redux methods and navigate for redirection
import { useDispatch } from "react-redux";
import { asLogin } from "../../../redux/modules/user/index.js";
import { useNavigate } from 'react-router-dom';
function Illustration() {
  const rDispatch = useDispatch();
  const navigate = useNavigate();
  const [rememberMe, setRememberMe] = useState(false);
  const [formData, updateFormData] = useState({email:"",password:""});

  const handleChange = (e) => {
    updateFormData({
      ...formData,

      // Trimming any whitespace
      [e.target.name]: e.target.value.trim(),
    });
  };
  useEffect(() => {
    if(localStorage.getItem("key")){
      navigate("/desafios");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const placeSubmitHandler = (event) => {
    event.preventDefault();
    const payload = {
      email: formData.email,
      password: formData.password,
    }
   rDispatch(asLogin(payload,navigate))
  };
  return (
    <IllustrationLayout
      title="Iniciar Sesión"
      description="¡Participa y gana muchos premios!"
      illustration={bgImage}
    >
      <MKBox
        component="form"
        role="form"
        onSubmit={placeSubmitHandler}
      >
        <MKBox mb={2}>
          <MKInput
            name="email"
            label="Email"
            type="email"
            fullWidth
            variant="outlined"
            onChange={handleChange}
          />
        </MKBox>
        <MKBox mb={2}>
          <MKInput
            name="password"
            label="Contraseña"
            type="password"
            fullWidth
            variant="outlined"
            onChange={handleChange}
          />
        </MKBox>
        <MKBox mt={4} mb={1}>
          <MKButton
            variant="gradient"
            color="buttonads"
            size="large"
            fullWidth
            type="submit"
            disabled={!(formData.email!=""&&formData.password!="")}
          >
            Ingresar
          </MKButton>
        </MKBox>
        <MKBox mt={3} textAlign="center">
          <MKTypography variant="button" color="text">
          ¿No tienes cuenta aún? {" "}
            <MKTypography
              component={Link}
              to="/create-account"
              variant="button"
              color="buttonads"
              fontWeight="medium"
              textGradient
            >
              Crear cuenta.
            </MKTypography>
          </MKTypography>
        </MKBox>
        <MKBox mt={3} textAlign="center">
          <MKTypography variant="button" color="text">
          ¿Olvidaste tu contraseña?{" "}
            <MKTypography
              component={Link}
              to="/reset"
              variant="button"
              color="buttonads"
              fontWeight="medium"
              textGradient
            >
               Recuperar cuenta
            </MKTypography>
          </MKTypography>
        </MKBox>
      </MKBox>
    </IllustrationLayout>
  );
}

export default Illustration;

/**
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Adslive Media (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "../../../../src/components/MKBox";
import MKTypography from "../../../../src/components/MKTypography";
function IllustrationLayout({
  header,
  title,
  description,
  illustration,
  children,
}) {

  return (
    <MKBox width="100%" height="100%" bgColor="adslive">      
      <Grid container>
        <Grid item xs={12} lg={6}>
          <MKBox
            display={{ xs: "none", lg: "flex" }}
            width="calc(100% - 2rem)"
            height="calc(100% - 2rem)"
            borderRadius="lg"
            ml={2}
            mt={2}
            sx={{ backgroundImage: `url(${illustration})` , backgroundPosition: "center"}}
          />
        </Grid>
        <Grid item xs={11} sm={8} md={5} lg={4} xl={3} sx={{ mx: "auto" }}>
          <MKBox
            display="flex"
            flexDirection="column"
            justifyContent="center"
            height="100vh"
          >
            <MKBox p={3} textAlign="center">
              {!header ? (
                <>
                  <MKBox mb={1} textAlign="center">
                    <MKTypography variant="h4" fontWeight="bold">
                      {title}
                    </MKTypography>
                  </MKBox>
                  <MKTypography variant="body2" color="text">
                    {description}
                  </MKTypography>
                </>
              ) : (
                header
              )}
            </MKBox>
            <MKBox p={3}>{children}</MKBox>
          </MKBox>
        </Grid>
      </Grid>
    </MKBox>
  );
}

// Setting default values for the props of IllustrationLayout
IllustrationLayout.defaultProps = {
  header: "",
  title: "",
  description: "",
  illustration: "",
};

// Typechecking props for the IllustrationLayout
IllustrationLayout.propTypes = {
  header: PropTypes.node,
  title: PropTypes.string,
  description: PropTypes.string,
  illustration: PropTypes.string,
};

export default IllustrationLayout;

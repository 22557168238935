import React from "react";
import Attr  from "./prueba.js";
import { useEffect } from "react";
// Routes
import routes from "../../shared/util/routes/routes";
import footerRoutes from "../../shared/util/routes/footer";
// Material Kit 2 PRO React examples
import DefaultNavbar from "../../shared/components/Navigation/DefaultNavbar";
import DefaultFooter from "../../shared/components/Footers/DefaultFooter";
// Material Kit 2 PRO React components
import MKBox from "../../components/MKBox";

// Import the redux methods
import {CheckUser} from "../../redux/modules/user"
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
const Ranking = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { uToken } = useSelector((state) => state.user);
  useEffect(()=>{
    dispatch(CheckUser(uToken!==""?uToken:localStorage.getItem("key"),navigate))
  },[])
  return (
    <>
      <DefaultNavbar
        routes={routes}cky
      />
      <Attr itemsPerPage={4} />
      <MKBox pt={6} px={1} sx={{marginTop:"auto"}}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
};

export default Ranking;
import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
export const userSlice = createSlice({
  name: "user",
  initialState: {
    uToken: "",
    uInfo:{},
  },
  reducers: {
    getToken: (state, actions) => {
      state.uToken = actions.payload;      
    },
    setInfo: (state, actions) => {
      state.uInfo = actions.payload;
    },
  },
});

export const asLogin = (data, navigate) => async (dispatch) => {
  try {
    const response = await axios.post(
      process.env.REACT_APP_ENPOINT+"/login",
      data
    );
    dispatch(getToken(response.data.access_token));
    localStorage.setItem("key", response.data.access_token)
    //dispatch(setNavlinks(""));
    navigate("/desafios");
    toast.dark("Bienvenido!");
  } catch (err) {
    toast.error(err.response.data.message);
  }
};

export const asCreateAccount = (data, navigate) => async (dispatch) => {
  try {
    const response = await axios.post(
      process.env.REACT_APP_ENPOINT+"/register",
      data
    );
    navigate("/");
  } catch (err) {
    toast.error(err.response.data.message);
  }
};


export const resetPassword = (data,navigate) => async (dispatch) => {
  try {
    const response = await axios.post(
      process.env.REACT_APP_ENPOINT+"/forgot",
      data
    );
    toast.info(response.data.message);
    navigate("/");
  } catch (err) {
    toast.info(err.response.data.message);
  }
};

export const newPwd = (data,navigate) => async (dispatch) => {
  try {
    const response = await axios.post(
      process.env.REACT_APP_ENPOINT+"/reset",
      data
    );
    toast.info(response.data.message);
    toast.success("Ahora puede Iniciar sesión");
    navigate("/")
  } catch (err) {
    toast.info(err.response.data.message);
  }
};

export const updateScore = (data,token) => async (dispatch) => {
  try {
    const response = await axios.put(
      process.env.REACT_APP_ENPOINT+"/update-score",
      data, {
        headers: {
          Authorization: token,
        },
      }
    );    
  } catch (err) {
    toast.info(err.response.data.message);
  }
};


export const GetUser = (token,navigate) => async (dispatch) => {
  try {
    const response = await axios.get(process.env.REACT_APP_ENPOINT+"/get-user", {
      headers: {
        Authorization: token,
      },
    });
    dispatch(setInfo(response.data));
  } catch (error) {
    navigate("/")
  }
};
export const CheckUser = (uToken,navigate) => async (dispatch)  =>{
  let IsAuth = false;
  if(uToken!==""&&localStorage.getItem('key'))
  {
    IsAuth = true;
  }  
  if(!IsAuth){    
    toast.error("Por favor Inicie Sesion");
    navigate("/")
  }
};

export const ClearUser = (navigate)=>async (dispatch) =>{
  localStorage.clear();
  dispatch(getToken(""));
  navigate("/")
}

// Action creators are generated for each case reducer function
export const { getToken,setInfo } = userSlice.actions;

export default userSlice.reducer;

// @mui material components
import { useEffect } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
// Material Kit 2 PRO React components
import MKBox from "../../../components/MKBox";
// Import the redux methods
import { GetUser } from "../../../redux/modules/user";
import {CheckUser} from "../../../redux/modules/user"
// Material Kit 2 PRO React examples
import DefaultInfoCard from "../../../shared/components/Cards/InfoCards/DefaultInfoCard";
import CenteredBlogCard from "../../../shared/components/Cards/BlogCards/CenteredBlogCard";

function FeaturesTwo() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { uToken } = useSelector((state) => state.user);
  let UserInfo = useSelector((state) => state.user.uInfo);
  useEffect(() => {
    dispatch(GetUser(uToken? uToken:localStorage.getItem("key"),navigate));
  }, []);
  if(UserInfo&& Object.keys(UserInfo).length > 0){
    return (
      <MKBox component="section" py={12}>
        <Container>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} lg={6}>
              <Grid container justifyContent="flex-start">
                <Grid item xs={12} md={6}>
                  <MKBox mb={5}>
                    <DefaultInfoCard
                      icon="mail"
                      title="Email"
                      color={"primary"}
                      description={UserInfo.email}
                    />
                  </MKBox>
                </Grid>
                <Grid item xs={12} md={6}>
                  <MKBox mb={5}>
                    <DefaultInfoCard
                      icon="phone"
                      title="Telefono"
                      color={"primary"}
                      description={UserInfo.phone}
                    />
                  </MKBox>
                </Grid>
                <Grid item xs={12} md={6}>
                  <MKBox mb={{ xs: 5, md: 0 }}>
                    <DefaultInfoCard
                      icon="score"
                      title="Puntos: "
                      color={"primary"}
                      description={UserInfo.score}
                    />
                  </MKBox>
                </Grid>
                <Grid item xs={12} md={6}>
                  <MKBox mb={{ xs: 5, md: 0 }}>
                    <DefaultInfoCard
                      icon="location_city"
                      title="Departamento"
                      color={"primary"}
                      description={UserInfo.Department.name}
                    />
                  </MKBox>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
              <CenteredBlogCard
                image="https://upload.wikimedia.org/wikipedia/commons/2/2c/Default_pfp.svg"
                title={UserInfo.name}
              />
            </Grid>
          </Grid>
        </Container>
      </MKBox>
    );
  }
  else{
    return(
      <>
      </>
    );
  }  
}

export default FeaturesTwo;

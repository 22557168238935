/**
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Adslive Media (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from "react-router-dom";

import { useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

// Material Kit 2 PRO React components
import MKBox from "../../../src/components/MKBox";
import MKTypography from "../../../src/components/MKTypography";
import MKInput from "../../../src/components/MKInput";
import MKButton from "../../../src/components/MKButton";

// Authentication layout components
import CoverLayout from "../components/CoverLayout";

// Images
import bgImage from "../../assets/images/prizesCover.png";

//store methods
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { asCreateAccount } from "../../redux/modules/user/index.js";

function Cover() {
  const rDispatch = useDispatch();
  const navigate = useNavigate();
  const [formState, setForm] = useState();
  const [terms, setTerms] = useState(false);  
  const handleChange = (e) => {
    setForm({
      ...formState,

      // Trimming any whitespace
      [e.target.name]: e.target.value.trim(),
    });
  };
  const placeSubmitHandler = (event) => {
    event.preventDefault();
    const payload = {
      email: formState.Email,
      password: formState.PWD,
      name: formState.Name,
      department: 4,
      phone: formState.Phone,
    };

    rDispatch(asCreateAccount(payload, navigate));
  };
  return (
    <CoverLayout image={bgImage}>
      <Card style={{backgroundColor: " #FF7412"}} onSubmit={placeSubmitHandler}>
        <MKBox
          variant="gradient"
          bgColor="dark"
          borderRadius="lg"
          coloredShadow="warning"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MKTypography variant="h4" fontWeight="medium" color="white" mt={1}>
          Únete a la diversión
          </MKTypography>
          <MKTypography display="block" variant="button" color="white" my={1}>
          Ingresa tus datos:
          </MKTypography>
        </MKBox>
        <MKBox bgColor=" #FF7412" p={3}>
          <MKBox component="form" role="form">
            <MKBox mb={2}>
              <MKInput
                name="Name"
                type="text"
                label="Nombre"
                fullWidth
                onChange={handleChange}
              />
            </MKBox>
            <MKBox mb={2}>
              <MKInput
                name="Phone"
                type="phone"
                label="Teléfono"
                fullWidth
                onChange={handleChange}
              />
            </MKBox>
            <MKBox mb={2}>
              <MKInput
                name="Email"
                type="email"
                label="Email"
                fullWidth
                onChange={handleChange}
              />
            </MKBox>
            <MKBox mb={2}>
              <MKInput
                name="PWD"
                type="password"
                label="Contraseña"
                fullWidth
                onChange={handleChange}
              />
            </MKBox>
            <MKBox display="flex" alignItems="center" ml={-1}>
              <Checkbox onClick={()=>{setTerms(!terms)}} />
              <MKTypography
                variant="button"
                fontWeight="regular"
                color="text"
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}                
              >
                   &nbsp;&nbsp;Estoy de acuerdo con los  &nbsp;
              </MKTypography>
              <MKTypography
                component="a"
                href="/terms"
                target="_blank"
                variant="button"
                fontWeight="bold"
                color="info"
                textGradient
              >
                Terminos y Condiciones
              </MKTypography>
            </MKBox>
            <MKBox mt={3} mb={1} >
              <MKButton variant="gradient" disabled={!terms} color="dark" fullWidth type="submit">
                Crear Cuenta
              </MKButton>
            </MKBox>
            <MKBox mt={3} mb={1} textAlign="center">
              <MKTypography variant="button" color="text">
                Ya tienes una cuenta?{" "}
                <MKTypography
                  component={Link}
                  to="/"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Iniciar Sesión
                </MKTypography>
              </MKTypography>
            </MKBox>
          </MKBox>
        </MKBox>
      </Card>
    </CoverLayout>
  );
}

export default Cover;

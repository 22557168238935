import { useState } from "react";
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Material Kit 2 PRO React components
import MKBox from "../../components/MKBox";
import MKButton from "../../components/MKButton";
// Material Kit 2 PRO React components
import SimpleBookingCard from "../../shared/components/Cards/BookingCards/SimpleBookingCard";

// Images
import product1 from "../../assets/images/soccerGoal.png";
import product2 from "../../assets/images/soccerChamp.png";
import product3 from "../../assets/images/soccerPool.png";
// Routes
import routes from "../../shared/util/routes/routes";
// Material Kit 2 PRO React examples
import DefaultNavbar from "../../shared/components/Navigation/DefaultNavbar";
//Modal components
import Modal from "@mui/material/Modal";
import Slide from "@mui/material/Slide";
// Import the redux methods
import { useEffect } from "react";
import { CheckUser, updateScore } from "../../redux/modules/user";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./plane.css";
function BlogPostTwo() {
  const [show1, setShow1] = useState(false);
  const toggleModal = () => setShow1(!show1);
  const [show2, setShow2] = useState(false);
  const toggleModal2 = () => setShow2(!show2);
  const [show3, setShow3] = useState(false);
  const toggleModal3 = () => setShow3(!show3);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { uToken, uInfo } = useSelector((state) => state.user);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    dispatch(
      CheckUser(uToken !== "" ? uToken : localStorage.getItem("key"), navigate)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleMessage = (event) => {
      event.preventDefault();
      if (event.data && event.origin==="https://adslive-advergame.s3.amazonaws.com") {
        const data = {
          score: event.data,
        };
        dispatch(
          updateScore(
            data,
            uToken !== "" ? uToken : localStorage.getItem("key")
          )
        );
      }
    };
    window.addEventListener("message", handleMessage);

    console.log(uInfo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const section = {
    height: "100%",
  };
  return (
    <MKBox height="calc(100vh - 1rem)">
      <MKBox width="100%" position="absolute" top="0.25rem">
        <DefaultNavbar routes={routes} dark />
        <MKBox py={7}>
          <Container>
            <Grid container pt={{ m: "2rem" }} spacing={2} sx={{ mt: 3 }}>
              <Grid item xs={12} md={6} lg={4} xl={4}>
                <div style={section}>
                  <MKBox mt={3}>
                    <SimpleBookingCard
                      image={product2}
                      title="Soccer caño"
                      description="Dispara con precisión para hacer todos los caños posibles y ganar puntos. Si fallas, solo tendrás tres oportunidades para continuar."
                      onclick={toggleModal}
                      action={{
                        type: "internal",
                        route: "/desafios",
                        color: "warning",
                        label: "Jugar Ahora",
                      }}
                    />
                    <Modal
                      open={show1}
                      onClose={toggleModal}
                      sx={{ display: "grid", placeItems: "center" }}
                    >
                      <Slide direction="down" in={show1} timeout={500}>
                        <MKBox className="game">
                          <iframe
                            title="Soccer Canio"
                            src="https://adslive-advergame.s3.amazonaws.com/Soccer+Champ+2022/index.html"
                            style={{ flexBasis: "100%" }}
                          ></iframe>
                          <MKButton
                            onClick={toggleModal}
                            variant="gradient"
                            color="info"                            
                            iconOnly
                            style={{marginLeft:"auto"}}
                          >
                            <Icon>close</Icon>
                          </MKButton>
                        </MKBox>
                      </Slide>
                    </Modal>
                  </MKBox>
                </div>
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={4}>
                <div style={section}>
                  <MKBox mt={3} color="challenges">
                    <SimpleBookingCard
                      image={product1}
                      title="Soccer goal"
                      description="Al estilo de una tanda de tiros penales, pon a prueba tu puntería y dispara para acertar en el blanco con la fuerza y dirección adecuadas."
                      onclick={toggleModal2}
                      action={{
                        type: "internal",
                        route: "/desafios",
                        color: "warning",
                        label: "Jugar Ahora",
                      }}
                    />
                    <Modal
                      open={show2}
                      onClose={toggleModal2}
                      sx={{ display: "grid", placeItems: "center" }}
                    >
                      <Slide direction="down" in={show2} timeout={500}>
                        <MKBox className="game">
                          <iframe
                            title="Soccer Canio"
                            src="https://adslive-advergame.s3.amazonaws.com/Soccer+Goal/index.html"
                            style={{ flexBasis: "100%" }}
                          ></iframe>
                          <MKButton
                            onClick={toggleModal2}
                            variant="gradient"
                            color="info"
                            iconOnly
                            style={{marginLeft:"auto"}}
                          >
                            <Icon>close</Icon>
                          </MKButton>
                        </MKBox>
                      </Slide>
                    </Modal>
                  </MKBox>
                </div>
              </Grid>
              <Grid item xs={12} md={6} lg={4} xl={4}>
                <div style={section}>
                  <MKBox mt={3}>
                    <SimpleBookingCard
                      image={product3}
                      title="Soccer pool"
                      description="Juega al clásico pool en versión fútbol. Mueve las fichas de tu equipo para anotar goles y defender tu portería del equipo rival."
                      onclick={toggleModal3}
                      action={{
                        type: "internal",
                        route: "/desafios",
                        color: "warning",
                        label: "Jugar Ahora",
                      }}
                    />
                    <Modal
                      open={show3}
                      onClose={toggleModal3}
                      sx={{ display: "grid", placeItems: "center" }}
                    >
                      <Slide direction="down" in={show3} timeout={500}>
                        <MKBox className="game">
                          <iframe
                            title="Soccer Canio"
                            src="https://adslive-advergame.s3.amazonaws.com/Soccer+Pool/index.html"
                            style={{ flexBasis: "100%" }}
                          ></iframe>
                          <MKButton
                            onClick={toggleModal3}
                            variant="gradient"
                            color="info"
                            iconOnly
                            style={{marginLeft:"auto"}}
                          >
                            <Icon>close</Icon>
                          </MKButton>
                        </MKBox>
                      </Slide>
                    </Modal>
                  </MKBox>
                </div>
              </Grid>
            </Grid>
          </Container>
        </MKBox>
      </MKBox>
    </MKBox>
  );
}

export default BlogPostTwo;

import React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
//redux methods and navigate for redirection
import { useDispatch } from "react-redux";
import { ClearUser } from "../../redux/modules/user";
const Blank = () => {    
  const rDispatch = useDispatch();
  const { uToken } = useSelector((state) => state.user);  
  const navigate = useNavigate();
  useEffect(()=>{
    rDispatch(ClearUser(navigate))
  },[])
  return(
    <>
    </>
  )
};

export default Blank;

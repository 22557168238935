/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Adslive Media (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useRef } from "react";
// @mui material components
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "../../components/MKBox";

// Material Kit 2 PRO React examples
import DefaultNavbar from "../../shared/components/Navigation/DefaultNavbar";

// About Us page sections
import Information from "./components/UserInfor";

// Routes
import routes from "../../shared/util/routes/routes";

// Images
import bgImage from "../../assets/images/prizesCover.png";

function AboutUs() {
  const headerRef = useRef(null);

  return (
    <>
      <DefaultNavbar
        routes={routes}       
        light
      />
      <MKBox
        ref={headerRef}
        minHeight="35vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0),
              rgba(gradients.dark.state, 0)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
      </MKBox>
      <Card
      style={{backgroundColor: "#FF7412"}}
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Information />
      </Card>
    </>
  );
}

export default AboutUs;
